import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import ReactCrop from 'react-image-crop';

import superagent from 'superagent';

const ASPECT = 16 / 10;
const MODAL_MAX_WIDTH = 466;
const NULL_CROPPABLE = {width: 1, height: 1};

const FILE_NAME_REGEX = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

const asPercent = (pixels, totalPixels) => pixels / totalPixels * 100;

const centerCrop = ({width, height}) => {
  if (width / height < ASPECT) {
    const newWidth = width * 0.9;
    const newHeight = newWidth / ASPECT;
    return {x: 5, y: asPercent(height - newHeight, height) / 2, width: 90};
  } else {
    const newHeight = height * 0.9;
    const newWidth = newHeight * ASPECT;
    return {x: asPercent(width - newWidth, width) / 2, y: 5, height: 90};
  }
}

const cropSize = ({width, height}=NULL_CROPPABLE) => {
  const aspectRatio = height !== 0 ? width / height : 1;
  const cropModalWidth = _.min([MODAL_MAX_WIDTH, width]);
  const cropModalHeight = cropModalWidth / aspectRatio;
  return {cropModalWidth, cropModalHeight};
}

export default class CropModal extends React.Component {
  constructor(props) {
    super(props);
    const {croppedImage, uncroppedImage} = props;

    const {url, crop_width, crop_x, crop_y} = croppedImage;
    const cropCoordinates = _.isNil(crop_x) ? centerCrop(uncroppedImage) : {
      x: asPercent(crop_x, uncroppedImage.width),
      y: asPercent(crop_y, uncroppedImage.height),
      width: asPercent(crop_width, uncroppedImage.width)
    };
    const crop = {...cropCoordinates, aspect: ASPECT};
    const {cropModalWidth, cropModalHeight} = cropSize(uncroppedImage);

    this.state = {
      open: true,
      cropImage: null,
      fileName: url,
      cropModalWidth,
      cropModalHeight,
      uncroppedUrl: uncroppedImage.url,
      crop
    };
  }

  updateCropState = (crop, pixelCrop) => {
    this.setState({crop, pixelCrop});
  }

  setCropImage = (res) => {
    let {filename, open} = this.state;
    if (!open) return;
    const disposition = res.header['content-disposition'];
    if (_.includes(disposition, 'inline')) {
      const matches = FILE_NAME_REGEX.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    this.setState({cropImage: res.text, fileName: filename});
  }

  cropImage = () => {
    const {pixelCrop} = this.state;
    const imageRef = this.imageRef;
    const canvas = document.createElement('canvas');
    this.drawImage(canvas, imageRef, pixelCrop);

    new Promise((resolve) => {
      canvas.toBlob(blob => {
        blob.name = this.state.fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(blob);
      }, 'image/jpeg');
    }).then((blob) => {
      const file = new File([blob], blob.name);
      file.preview = this.fileUrl;
      this.props.updateCroppedImage(file, pixelCrop);
    });
  }

  drawImage = (canvas, imageRef, pixelCrop) => {
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const context = canvas.getContext('2d');

    context.drawImage(
      imageRef,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );
  }

  componentDidMount() {
    superagent.get(`/listing_media/${_.get(this, 'props.croppedImage.id')}`).then(this.setCropImage);
  }

  render() {
    const {
      open,
      cropImage,
      cropModalWidth,
      cropModalHeight,
      crop,
      uncroppedUrl
    } = this.state;

    const {toggle} = this.props;

    return (
      <Modal isOpen={open} toggle={toggle}>
        <ModalBody className="d-flex justify-content-center">
          {cropImage !== null ? (
            <ReactCrop
              src={cropImage}
              onChange={this.updateCropState}
              onImageLoaded={(i) => this.imageRef = i}
              crop={crop}
            />
          ) : (
            <div>
              <div style={{
                background: `center no-repeat url(${uncroppedUrl})`,
                backgroundSize: 'contain',
                width: cropModalWidth,
                height: cropModalHeight,
                opacity: 0.2
              }}/>
              <div className="uploading-notice crop">Preparing image</div>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-center">
          {cropImage !== null ? (
            <Button color="primary" onClick={this.cropImage}>Crop</Button>
          ) : (
            <Button color="primary" disabled>Loading Image</Button>
          )}
          <Button color="outline-primary" onClick={this.props.toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}
