import React from 'react';
import classnames from 'classnames';

export default function ListingMarker({location, active, setActive}) {
  const {id, name, address, city, state, zipcode, listing_count} = location;
  return (
    <div className={classnames("marker", {active})} onClick={(e) => setActive(id)}>
      <div className="marker-overlay">
        <div className="tooltip-wrapper">
          <div title={name} className="listing-tooltip col-md-auto">
            <h4 className="tooltip-name">{name}</h4>
            {address}
            <br/>
            {city}, {state} {zipcode}
            <div className="available">
              <h4>{listing_count}</h4>
              Horses Available
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
