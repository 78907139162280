import { useState } from "react";
import ahoy from 'ahoy.js';
import flash from 'shared/flash';
import { subscribeSearch, unsubscribeSearch } from 'shared/api_request_helpers';
import { filterUnspecifiedTerms } from "../helpers/terms";

const LEGAL_OPT_IN_MESSAGE = `
  By providing my email address and saving this search, I agree to receive emails from the ASPCA, which may include updates on horses that meet my saved search criteria and promotional emails from the ASPCA Right Horse program. I understand I can unsubscribe or remove a saved search at any time.
`;
const SUBSCRIBED_MESSAGE = `
  Your search is saved! We’ll send you an email as soon as your #RightHorse is listed for adoption.
  You can manage your saved searches at anytime in the “Saved Search” panel. You can access this information
  in your account panel located on the top right corner of your browser.
`;
const UNSUBSCRIBED_MESSAGE = `
  You have unsubscribed to this search and will no longer receive emails about new equines that match this search.
`;

export default function SearchNotificationCheckbox({terms, searchNotificationId: search_notification_id, adopter}) {
  const [searchNotificationId, setSearchNotificationId] = useState(search_notification_id);

  const toggleSubscription = async () => {
    try {
      if (!searchNotificationId) {
        let optIn;
        if (!adopter.notification_opt_in_at) {
          if (confirm(LEGAL_OPT_IN_MESSAGE)) {
            optIn = new Date();
          } else {
            ahoy.track("NOTIFICATION_OPT_IN_DECLINE", {user_type: "Adopter", user_id: adopter.id});
          }
        }

        const { data } = await subscribeSearch(filterUnspecifiedTerms(terms), optIn);
        setSearchNotificationId(_.get(data, 'id'));
        flash('success', SUBSCRIBED_MESSAGE);
      } else {
        await unsubscribeSearch(searchNotificationId);
        setSearchNotificationId(null);
        flash('success', UNSUBSCRIBED_MESSAGE);
      }
    }
    catch (e) {
      flash('error', _.get(e.response, 'data.error', e.message));
    }
  };
  return (
    <input type="checkbox" checked={!!searchNotificationId} onChange={toggleSubscription} />
  );
}
