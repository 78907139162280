export const MAX_STEPS = 6;
export const MIN_STEPS = 1;

export const breedsToString = string => _.join(string, ', ');
export const breedsToShortString = string => string?.length > 1 ? `${string[0]}, Cross` : string;

export function setInitialStatus(status, days_remaining, EXPIRING_SOON_DAYS) {
  if (status == "published" && days_remaining <= 0) {
    return "expired";
  } else if (status == "published" && days_remaining <= EXPIRING_SOON_DAYS) {
    return "expiring_soon";
  } else {
    return status;
  }
}
