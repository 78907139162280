import ListingMarker from './ListingMarker';
import GoogleMap, { fitBounds } from 'google-map-react';

const CENTER_US = {lat: 39.5, lng: -98.35};

const mapPosition = (center, bounds, map) => {
  if (_.isFinite(center?.lat)) {
    return {center: {lat: parseFloat(center.lat), lng: parseFloat(center.lng)}, zoom: 12};
  } else if (bounds && map) {
    return fitBounds(bounds, {width: map.clientWidth, height: map.clientHeight});
  } else {
    return {center: CENTER_US, zoom: 4};
  }
}

export default class ListingMap extends React.Component {
  state = {activeLocation: null}

  mapRef = React.createRef();

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  componentDidCatch(error, info) {
    const logger = window.rollbar || console;
    logger.warn("Google Map Error in ListingMap", {error, info, props: this.props});
  }

  setActive = (id) => this.setState({activeLocation: id});

  closeActive = () => this.setActive(null);

  render() {
    const {googleMapsKey, locations, bounds} = this.props;
    const {activeLocation, hasError} = this.state;
    if (!googleMapsKey) return null;
    if (hasError) return <div className="alert alert-warning">The map cannot be displayed. Please try again later</div>;

    const {center, zoom} = mapPosition(this.props.center, bounds, this.mapRef.current);

    return (
      <div className="map" ref={this.mapRef}>
        <GoogleMap
          bootstrapURLKeys={{key: googleMapsKey, language: 'en'}}
          options={{styles: MAP_STYLE}}
          center={center}
          zoom={zoom}
          onClick={this.closeActive}
        >
          {_.map(locations, (location) => {
            const {latitude, longitude} = location;
            if (latitude && longitude) {
              return <ListingMarker
                key={location.id}
                lat={latitude}
                lng={longitude}
                location={location}
                setActive={this.setActive}
                active={activeLocation == location.id} />;
            } else {
              return null;
            }
          })}
        </GoogleMap>
      </div>
    )
  }
}

const MAP_STYLE =
[
  {
    "featureType": "administrative",
    "elementType": "all",
    "stylers": [
      {
        "lightness": "40"
      },
      {
        "gamma": "1.73"
      },
      {
        "weight": "2.24"
      },
      {
        "hue": "#00ff3b"
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#444444"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "all",
    "stylers": [
      {
        "color": "#f2f2f2"
      }
    ]
  },
  {
    "featureType": "landscape.natural.landcover",
    "elementType": "all",
    "stylers": [
      {
        "lightness": "17"
      },
      {
        "saturation": "-45"
      },
      {
        "gamma": "4.75"
      },
      {
        "weight": "1.46"
      }
    ]
  },
  {
    "featureType": "landscape.natural.terrain",
    "elementType": "all",
    "stylers": [
      {
        "lightness": "-26"
      },
      {
        "gamma": "2.68"
      },
      {
        "saturation": "35"
      }
    ]
  },
  {
    "featureType": "landscape.natural.terrain",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "lightness": "44"
      },
      {
        "weight": "4.98"
      },
      {
        "gamma": "8.61"
      },
      {
        "hue": "#ff0000"
      }
    ]
  },
  {
    "featureType": "landscape.natural.terrain",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "lightness": "53"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "all",
    "stylers": [
      {
        "saturation": -100
      },
      {
        "lightness": 45
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "all",
    "stylers": [
      {
        "color": "#BBD9E1"
      },
      {
        "visibility": "on"
      }
    ]
  }
]
