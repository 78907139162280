import axios from "axios";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { Typeahead } from 'react-bootstrap-typeahead';
import { preventDefault } from 'shared/events';

const modalContent = {
  'initiate': {
    'TITLE': "Request Transfer",
    'BUTTON_TEXT': "Submit Request"
  },
  'cancel': {
    'TITLE': "Cancel Transfer",
    'BUTTON_TEXT': "Yes, Cancel Transfer"
  },
  'complete': {
    'TITLE': "Complete Transfer",
    'BUTTON_TEXT': "Yes, Complete Transfer"
  }
}

export default class TransferListingOptions extends React.Component {
  state = {
    isOpen: false,
    organizationOptions: [],
    selectedOrganization: [],
    csrfToken: ''
  }

  componentDidMount() {
    const csrfToken = _.get(document.querySelector('[name=csrf-token]'), 'content');
    this.setState({csrfToken});
  }

  showModal = () => {
    if (this.props.action === 'initiate' && _.isEmpty(this.state.organizationOptions)) {
      axios.get('/api/v1/organizations').then((response) => {
        this.setState({organizationOptions: response.data});
      });
    }
    this.setState({isOpen: true});
  }

  disableButton = () => this.props.action === 'initiate' && _.isEmpty(this.state.selectedOrganization);

  render() {
    const {listing, transfer:{id, organization_name}={}, action} = this.props;
    const {isOpen, organizationOptions, selectedOrganization, csrfToken} = this.state;
    const formAction = action === 'initiate' ? '/listing_transfers' : `/listing_transfers/${id}`

    return (
      <div>
        <a href="#" className="dropdown-item" onClick={preventDefault(this.showModal)}>
          {modalContent[action]['TITLE']}
        </a>
        <Modal show={isOpen} onHide={() => this.setState({isOpen: false})} centered>
          <form acceptCharset="UTF-8" action={formAction} method="post">
            <Modal.Header closeButton>
              <Modal.Title>{modalContent[action]['TITLE']}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {action === 'initiate' && (
                <React.Fragment>
                  <label htmlFor="organization_id">Select an organization</label>
                  <Typeahead
                    id="organization_id"
                    name="organization_id"
                    placeholder="Organization name"
                    options={organizationOptions}
                    selected={selectedOrganization}
                    onChange={(selected) => this.setState({selectedOrganization: selected})}
                    labelKey="name" />
                  <input
                    name="listing_transfer[organization_id]"
                    readOnly
                    type="hidden"
                    value={_.get(selectedOrganization[0], 'id') || ''}
                    onChange={_.noop} />
                  <input name="listing_transfer[listing_id]" readOnly type="hidden" value={listing.id} />
                </React.Fragment>
              )}
              {action === 'cancel' && (
                <React.Fragment>
                  <p>Are you sure that you would like to cancel the transfer of <strong>{listing.name}</strong> to <strong>{organization_name}</strong>?</p>
                  <input name="_method" readOnly type="hidden" value="patch" />
                  <input name="listing_transfer[status]" readOnly type="hidden" value="canceled" />
                </React.Fragment>
              )}
              {action === 'complete' && (
                <React.Fragment>
                  <p>This will archive your listing for <strong>{listing.name}</strong> and create a new, unpublished listing for <strong>{organization_name}</strong>.</p>
                  <p>Are you sure that you would like to complete the transfer?</p>
                  <input name="_method" readOnly type="hidden" value="patch" />
                  <input name="listing_transfer[status]" readOnly type="hidden" value="completed" />
                </React.Fragment>
              )}
              <input name="authenticity_token" readOnly type="hidden" value={csrfToken} />
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={this.disableButton()}
              >{modalContent[action]['BUTTON_TEXT']}</button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    )
  }
}
