import ListingCard from './ListingCard';
import MoveToDraftsButtons from './DashboardListingCard/components/MoveToDraftsButtons';
import ActiveButtons from './DashboardListingCard/components/ActiveButtons';
import ExpirationButtons from './DashboardListingCard/components/ExpirationButtons';
import DraftButtons from './DashboardListingCard/components/DraftButtons';
import { submitListingAction } from './shared/api_request_helpers';
import { setInitialStatus } from './shared/listing_helpers';

const LISTING_BUTTONS = {
  archived: MoveToDraftsButtons,
  stalled: DraftButtons,
  adopted: MoveToDraftsButtons,
  published: ActiveButtons,
  expired: ExpirationButtons,
  expiring_soon: ExpirationButtons,
  draft: DraftButtons
};

export default class DashboardListingCard extends React.Component {
  state = {
    listing: this.props.listing
  }

  submitListing = (action, status_reason) => {
    const {listing} = this.props;
    submitListingAction(listing, action, status_reason, (error, response) => {
      if (!_.isEmpty(_.get(response.body, 'errors', {}))) {
        window.location.href = `/listings/${listing.id}/edit`;
      } else {
        this.setState(({listing}) => ({
          listing: {
            ...listing,
            ..._.pick(response.body, ['status', 'days_remaining', 'percent_remaining'])
          }
        }));
      }
    });
  }

  render() {
    const {EXPIRING_SOON_DAYS, STATUS_REASONS, userType, userID} = this.props;
    const {days_remaining, status, active_transfer} = this.props.listing;
    const {listing} = this.state;

    const initialStatus = setInitialStatus(status, days_remaining, EXPIRING_SOON_DAYS);
    const ListingButtons = LISTING_BUTTONS[initialStatus];
    const moved = listing.status !== status;

    return (
      <div className="card-column">
        <ListingCard
          listing={{...listing, status}}
          moved={moved}
          noToggler
          contributor
          userType={userType}
          userID={userID}/>
        <ListingButtons
          submitListingAction={this.submitListing}
          listing={listing}
          moved={moved}
          initialStatus={initialStatus}
          statusReasons={STATUS_REASONS}/>
        {active_transfer && (
          <p className="listing-data">
            {
              active_transfer.status == 'initiated' ? 'Pending Transfer to' : 'Transfer Accepted by'
            }: <strong>{active_transfer.organization_name}</strong>
          </p>
        )}
      </div>
    );
  }
}
