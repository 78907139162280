import PropTypes from 'prop-types';
import queryString from 'query-string';

import {searchListings} from 'shared/api_request_helpers';
import {ResultsMessage} from 'shared/displayHelpers';
import PersistedSearch from '../helpers/PersistedSearch';
import {
  filterUnspecifiedTerms, similarTerms, DEFAULT_TERMS, HIDDEN_DEFAULT_TERMS
} from '../helpers/terms';

import ListingCard from '@/components/ListingCard';
import Filters from '../components/Filters';
import ListingMap from '../components/Map';
import SearchNotificationCheckbox from '../components/SearchNotificationCheckbox';

import FUTURE_HORSE from "@images/future-horse.png";

const PER_PAGE = 30;
const SHOW_SUGGESTED = 12;

const setFilteredTerms = (terms) => {
  const qs = queryString.stringify(filterUnspecifiedTerms(terms));
  history.replaceState(history.state, '', `search${_.isEmpty(qs) ? '' : '?'}${qs}`);
}

const normalizedQueryString = (rawQueryString) => {
  return _.reduce(queryString.parse(rawQueryString), (state, value, key) => {
    if (key === 'distance') return {...state, [key]: parseInt(value, 10)};
    if (_.includes(['breed', 'discipline'], key)) return {...state, [key]: _.flatten([value])};
    if (_.isArray(value)) return {...state, [key]: [parseInt(value[0], 10), parseInt(value[1], 10)]};
    return {...state, [key]: value};
  }, {});
}

const useCachedTerms = () => {
  const terms = PersistedSearch.load() || DEFAULT_TERMS;
  setFilteredTerms(terms);
  return terms;
}

const useQueryFilterTerms = (queryFilters) => {
  return {...DEFAULT_TERMS, ...queryFilters};
}

export default class Search extends React.Component {
  static propTypes = {
    center: PropTypes.array,
    zoom: PropTypes.number,
  };

  constructor(props, context) {
    super(props, context);
    const queryFilters = normalizedQueryString(location.search);
    const terms = _.isEmpty(queryFilters) ? useCachedTerms() : useQueryFilterTerms(queryFilters);
    this.requestListings = _.debounce(this.postTerms.bind(this), 1500);
    this.showMore = _.throttle(this.moreListings, 250);
    this.state = {
      center: {},
      bounds: {},
      advanced: false,
      mode: 'DEFAULT',
      terms,
      results: [],
      suggestedResults: [],
      maxResults: PER_PAGE,
      loading: true,
      locations: props.default_map_locations
    };
  }

  componentDidMount() {
    const {organization_id} = this.state.terms;
    const organization = _.find(this.props.organizations, {id: _.toNumber(organization_id)})
    if (!organization && organization_id) {
      this.clearFilters();
    } else {
      const selectedIndex = history.state ? history.state.index : 0;
      const roundedResults = Math.ceil(selectedIndex / PER_PAGE) * PER_PAGE;
      this.postTerms(roundedResults);
    }
    document.addEventListener('scroll', this.watchScroll, false);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.watchScroll);
  }

  watchScroll = (e) => {
    const nearBottom = window.scrollY > document.body.offsetHeight - window.innerHeight*2;
    if (nearBottom) this.showMore();
  }

  moreListings = () => {
    const {count=PER_PAGE, results} = this.state;
    const maxResults = Math.min(count, _.get(this.state, 'maxResults', 0) + PER_PAGE);
    if (count > results.length) this.postTerms(maxResults, results.length);

    this.setState({maxResults});
  }

  setFilterTerm = (name, value) => {
    const terms = {...this.state.terms, [name]: value};
    setFilteredTerms(terms);
    history.pushState({}, '');
    this.setState({terms, results: [], loading: true}, this.requestListings);
  }

  setTermLabel = ({target: {name, value}}) => {
    this.setState(({terms}) => ({terms: {...terms, [name]: value}}));
  }

  postTerms(maxIndex=PER_PAGE, offset=0) {
    const {terms} = this.state;
    maxIndex = _.max([PER_PAGE, maxIndex]);
    const limit = maxIndex - offset;
    if (!this.pendingRequest && limit > 0) {
      this.pendingRequest = true;
      this.setState({loading: true});

      searchListings({...terms, limit, offset})
        .then(({results, count, center, bounds, locations, search_notification_id}) => {
          PersistedSearch.save(terms);
          this.setState(({results: existingRecords}) => ({
            loading: false,
            results: _.uniqBy([...existingRecords, ...results], 'id'),
            maxResults: results.length,
            center,
            bounds,
            locations,
            count,
            searchNotificationId: search_notification_id
          }));
          this.pendingRequest = false;

          if (!_.isMatch(terms, this.state.terms)) {
            this.setState({results: [], loading: true}, this.postTerms);
          } else {
            _.delay(() => {
              this.scrollToElement?.scrollIntoView()
              if (count <= SHOW_SUGGESTED) this.getSuggested();
            }, 200);
          }
        })
        .catch((error) => {
          if (error) console.warn("Failed to fetch search results", error);
          this.pendingRequest = false;
          this.setState({loading: false});
        });
    }
  }

  getSuggested() {
    const {terms, results} = this.state;
    const excluded_ids = _.map(results, 'id');
    searchListings({...similarTerms(terms), limit: PER_PAGE, excluded_ids})
      .then(({results}) => {
        this.setState({suggestedResults: results});
      })
      .catch((error) => {
        if (error) console.warn("Failed to fetch suggested results", error);
      });
  }

  setViewMode = (viewType) => {
    this.setState(({mode}) => ({mode: mode === viewType ? "DEFAULT" : viewType}));
  }

  clearFilters = () => {
    this.setState({terms: {...DEFAULT_TERMS, ...HIDDEN_DEFAULT_TERMS}, results: []}, () => {
      history.replaceState(history.state, '', 'search');
      PersistedSearch.clear();
      this.postTerms();
    });
  }

  persistIndex = (i) => {
    history.replaceState({index: i}, `Listing ${i}`, `#${i}`);
  }

  setScrollToRef = (element) => {
    this.scrollToElement = element;
  }

  render() {
    const {
      mode, loading, center, bounds, terms, results, suggestedResults, maxResults, locations,
      searchNotificationId
    } = this.state;
    const {
      googleMapsKey, breeds, genders, disciplines, organizations, favorite_ids, adopter,
      userType, userID
    } = this.props;

    const subscribableSearch = adopter && !loading && results.length < 50 && !_.isEmpty(filterUnspecifiedTerms(terms));

    return (
      <div className="search">
        <div className="filters">
          <div className="container">
            <Filters
              advanced={mode === "FILTERS"}
              setFilterTerm={this.setFilterTerm}
              setTermLabel={this.setTermLabel}
              {...{terms, breeds, genders, disciplines, organizations, googleMapsKey}}/>

            <div className="d-flex justify-content-between" style={{gap: '10px'}}>
              <button onClick={() => this.setViewMode("FILTERS")} className="btn btn-secondary">
                {mode === "FILTERS" ? 'Less Filters' : 'More Filters'}
              </button>
              <button onClick={this.clearFilters} className="btn btn-outline-primary">
                Clear Filters
              </button>
              <button onClick={() => this.setViewMode("MAP")} className="btn btn-primary">
                {mode === "MAP" ? "Hide Map" : "View Map"}
              </button>
            </div>
          </div>
        </div>

        {mode === "MAP" && <ListingMap {...{center, bounds, googleMapsKey, locations}}/>}

        <div className="container">
          <ResultsMessage count={results.length} loading={loading}/>

          {results.length > 0 && (
            <div className="card-row mt-3 search-results">
              {results.map((listing, i) => (
                <div key={listing.id}
                  ref={_.get(history, "state.index") === i ? this.setScrollToRef : undefined}
                  className="card-column"
                  onClick={() => this.persistIndex(i)}
                >
                  <ListingCard
                    listing={listing}
                    adopter={adopter}
                    favorite={_.includes(favorite_ids, listing.id)}
                    userType={userType}
                    userID={userID}/>
                </div>
              ))}
              {subscribableSearch && (
                <div className="card-column">
                  <div className="card listing-card notification-card" style={{"backgroundColor": "#ffe9db"}}>
                    <img src={FUTURE_HORSE} className="listing-card-image"/>
                    <div className="card-body">
                      <h5 className="card-title">Let us find your #RightHorse!</h5>
                      <label className="mb-0">
                        <SearchNotificationCheckbox terms={terms} searchNotificationId={searchNotificationId} adopter={adopter}/>{" "}
                        I want to receive an email when a new equine is listed that matches this search.
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {subscribableSearch && results.length === 0 && (
            <div className="alert alert-primary text-center mb-4" style={{marginBottom: '80px'}}>
              <h4>Let us find your #RightHorse!</h4>
              <label>
                <SearchNotificationCheckbox terms={terms} searchNotificationId={searchNotificationId} adopter={adopter}/>{" "}
                I want to receive an email when a new horse is listed that matches this search.
              </label>
            </div>
          )}

          {(_.inRange(results.length, 1, maxResults) && (results.length > 0)) && (
            <div className="text-center">
              <button onClick={this.showMore} className="btn btn-primary mb-4">
                Show More
              </button>
            </div>
          )}
          {suggestedResults.length > 0 && <>
            <h3>You might {results.length > 0 && 'also'} like...</h3>
            {terms.location && (!terms.distance || terms.distance < 1000) ? (
              <div className="alert alert-info">
                The #RightHorse is never in the wrong place. Ask about transportation options when
                inquiring on your #RightHorse.
              </div>
            ) : null}
            <div className="card-row mt-3 suggested-results">
              {suggestedResults.map((listing) => (
                <div key={listing.id} className="card-column">
                  <ListingCard
                    listing={listing}
                    adopter={adopter}
                    favorite={_.includes(favorite_ids, listing.id)}
                    userType={userType}
                    userID={userID}
                    trackEvent="SUGGESTED_LISTING_CLICK"/>
                </div>
              ))}
            </div>
          </>}
        </div>
      </div>
    );
  }
}
