import pluralize from 'pluralize';
import { listingPath } from './ListingCard';
import TransferCardButtons from './TransferListingCard/components/TransferCardButtons';
import { breedsToShortString } from './shared/listing_helpers';

export default class TransferListingCard extends React.Component {
  render() {
    const {listing, transfer, transfer_type, org_name, locations} = this.props;
    const {name, gender, breeds, slug, age, height, cover} = listing;

    const breed = breedsToShortString(breeds);

    const statusMessage = () => {
      if (transfer.status == "initiated") {
        return transfer_type == "inbound" ? "Transfer Requested" : "Pending Transfer to"
      } else {
        return transfer_type == "inbound" ? "Transfer Accepted" : "Transfer Accepted by"
      }
    }

    return (
      <div>
        <a className="card" href={slug ? listingPath(slug) : "#"}>
          <div className="card-body">
            <img src={cover} className="listing-card-image" alt=""/>
            <h5 className="card-title text-left">
              {name}
            </h5>
            <div className="card-text text-left">
              {transfer_type == "inbound" && <p>{org_name}</p>}
              <p className="listing-data">
                {breed} / {gender} / {pluralize('year', age, true)} / {height} HH
              </p>
            </div>
            <div className="card-text text-left mt-4">
              {transfer_type == "inbound" && (
                <p className="listing-data"><strong>{statusMessage()}</strong></p>
              )}
              {transfer_type == "outbound" && (
                <p className="listing-data">{statusMessage()}: <strong>{org_name}</strong></p>
              )}
            </div>
          </div>
        </a>
        <TransferCardButtons
          transferType={transfer_type}
          transfer={transfer}
          listingName={name}
          orgName={org_name}
          locations={locations}
        />
      </div>
    );
  }
}
