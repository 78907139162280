export default function flash(type="", message) {
  if (!_.isEmpty(message)) {
    $('.notice').remove();
    $(document.body).append(`
      <div class="notice ${type}">
        <div class="container">
          ${message}
        </div>
      </div>
    `);
  } else {
    try { throw new Error('empty message sent to flash?') } catch (e) { console.warn(e) }
  }
}
