import { useMemo, useState, useEffect, useRef } from 'react';
import { createContext } from 'react';
import { get, set, curry, update, omit, reject, assign } from 'lodash/fp';

import {
  updateListing,
  deleteListingDocument,
  deleteListingMedium,
  uploadAttachments,
  uploadImageCropping,
  updateImageOrder
} from 'shared/api_request_helpers';


const imageFromResponse = (res) => {
  const {id, carrierwave_media={}, crop_width, crop_height, crop_x, crop_y} = _.get(res, 'data', {});
  const url = `${carrierwave_media.cover.url}?${crop_width}x${crop_height}+${crop_x}+${crop_y}`;

  return {id, url, crop_width, crop_height, crop_x, crop_y};
};

const updateCoverImage = (coverImageId, res) => (images) => images.map((image) => {
  return image.id === coverImageId ? imageFromResponse(res) : image;
});

const updateStep = async (id, step) => updateListing(id, {step, editing: true});

const newContextAPI = ({
  listing, listingRef, setListing,
  errors, errorsRef, setErrors,
  lastUpdate, receivedUpdateAt,
  stepValidation
}) => {
  const holdValidationFor = (func) => (...args) => {
    const promise = func(...args);
    stepValidation.current.queue.push({promise});
    return promise;
  }

  const processErrors = (response) => {
    const errors = _.get(response, 'data.errors')

    if (!_.isEmpty(errors)) {
      setErrors(assign(errors));
    }

    return errors;
  }

  const submitStatusUpdate = async (id, status) => {
    try {
      const response = await updateListing(id, {status});

      const errors = processErrors(response);
      if (!_.isEmpty(errors)) {
        throw errors;
      }
      return response.data;
    } catch (e) {
      console.warn('StatusUpdate failed', e);
      if (!e.isAxiosError) {
        throw e;
      }
    }
  }

  const api = {
    listing,
    errors,
    getListing() {
      return listingRef.current;
    },
    getErrors() {
      return errorsRef.current;
    },
    uploadAttachments: holdValidationFor(async(type, acceptedFiles) => {
      const key = type === 'images' ? 'listing_media' : 'listing_documents';
      const response = await uploadAttachments(key, listing.id, acceptedFiles);
      const stateKey = type === 'images' ? 'images' : 'documents';

      if (_.some(response.data, {id: null})) {
        alert("Unable to upload. Please use a different image.");
        window.Rollbar && Rollbar.info("Invalid image uploaded");
      }
      const value = _.reject(response.data, {id: null});
      setListing(set(stateKey, value));
    }),

    uploadImageCrop: holdValidationFor(async (coverImageId, pixelCrop) => {
      try {
        const response = await uploadImageCropping(coverImageId, pixelCrop)
        setListing(update('images', updateCoverImage(coverImageId, response)));
      } catch (e) {
        if (!e.isAxiosError) {
          throw e;
        }
      }
    }),

    updateImageOrder: holdValidationFor(async (order) => {
      try {
        await updateImageOrder(listing.id, order)
      } catch (e) {
        if (!e.isAxiosError) {
          throw e;
        }
      }
    }),

    deleteAttachment: holdValidationFor(async (type, id) => {
      if (!id) {
        console.warn(`deleteAttachment id is empty: '${String(id)}'`);
        return;
      }

      switch(type) {
      case 'images':
        await deleteListingMedium(id);
        break;
      case 'documents':
        await deleteListingDocument(id)
        break;
      default:
        throw new Error(`invalid attachment type: '${type}'. Deletion failed.`);
      }

      setListing(update(type, reject({id})));
    }),

    updateField: holdValidationFor(curry(async (name, value) => {
      const thisUpdate = new Date();
      lastUpdate.current = thisUpdate;

      const localListing = await new Promise((resolve) => {
        setListing((_listing) => {
          listing = set(name, value, _listing);
          resolve(listing);
          return listing;
        });
      });

      if (_.first(name) === 'behavior_profile') {
        name = "behavior_profile_attributes";
        value = localListing.behavior_profile;
      }
      const response = await updateListing(listing.id, {[name]: value});
      processErrors(response);
      const updatedListing = response.data;
      if (lastUpdate.current <= thisUpdate) {
        if (updatedListing.status === "published") {
          setListing(set(name, get(name, updatedListing)));
          setListing(set('large_images', get('large_images', updatedListing)));
        } else {
          setListing(updatedListing);
        }
        receivedUpdateAt.current = thisUpdate;
        setErrors(omit(name));
      }
    })),

    waitForPendingOperations: async () => await stepValidation.current.promise,

    validateStep: ((process) => {
      return async (step) => {
        const wait = stepValidation.current.processing;
        stepValidation.current.queue.push({step, listing});

        if (wait) return stepValidation.current.promise;

        stepValidation.current.processing = true;
        await stepValidation.current.promise;
        stepValidation.current.promise = new Promise((resolve) => process(resolve))
        await stepValidation.current.promise;
        stepValidation.current.processing = false;
      }
    })(async function process(resolve) {
      if (stepValidation.current.queue.length === 0) {
        resolve();
        return;
      }

      const {step, listing, promise} = stepValidation.current.queue.shift();
      if (promise) {
        await promise;
      } else {
        const response = await updateStep(listing.id, step);
        if (_.isEmpty(processErrors(response))) {
          setListing(response.data);
        }
      }
      setTimeout(() => process(resolve), 1);
    }),

    publish: () => submitStatusUpdate(listing.id, "published"),
    unpublish: () => submitStatusUpdate(listing.id, "draft")
  };

  return api;
};

export const useDistinctContextAPI = (value, updater=_.noop) => {
  const [listing, setListing] = useState(value);
  const [errors, setErrors] = useState(value?.errors);

  const listingRef = useRef(listing);
  const errorsRef = useRef(errors);

  const lastUpdate = useRef();
  const receivedUpdateAt = useRef();
  const stepValidation = useRef({queue: [], promise: Promise.resolve(), processing: false});

  useEffect(() => {
    setListing(value)
    setErrors(value.errors);
  }, [value]);

  useEffect(() => {
    listingRef.current = listing;
    if (listing != value) updater(listing);
  }, [listing]);

  useEffect(() => {
    errorsRef.current = errors;
  }, [errors]);

  const contextAPI = useMemo(() => {
    return newContextAPI({listing, listingRef, setListing, errors, errorsRef, setErrors: (...args) => {
      setErrors(...args)
    }, lastUpdate, receivedUpdateAt, stepValidation});
  }, [listing, errors]);

  return contextAPI;
}

const ListingContext = createContext();
export function Provider({value, updater, children}) {
  const contextAPI = useDistinctContextAPI(value, updater);

  return <ListingContext.Provider value={contextAPI}>
    {children}
  </ListingContext.Provider>
}

export default ListingContext;
