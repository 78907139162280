import { createAPIKey } from 'shared/api_request_helpers';
import flash from 'shared/flash';

import KeyRow from './APIKeys/components/KeyRow';

export default class APIKeys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {apiKeys: props.api_keys}
  }

  createKey = () => createAPIKey()
    .then(({data}) => {
      if (data) flash("success", "Successfully created a new API Key")
      this.setState(({apiKeys}) => ({apiKeys: [...apiKeys, data]}));
    })
    .catch((error) => console.log(error));

  render() {
    const {apiKeys} = this.state;

    return (
      <div>
        <table className="table table-shaded">
          <thead>
            <tr>
              <th className="created-column">Created</th>
              <th className="label-column">Label</th>
              <th className="key-column">API Key</th>
              <th className="status-column">Status</th>
            </tr>
          </thead>
          <tbody>
            {_.map(apiKeys, ((api_key) => (
              <KeyRow key={api_key.auth_token} {...api_key}/>
            )))}
          </tbody>
        </table>
        <div className="text-center">
          <button className="btn btn-outline-primary" onMouseDown={this.createKey}>Create a Key</button>
        </div>
      </div>
    );
  }
}
